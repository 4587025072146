exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-detail-js": () => import("./../../../src/pages/blog-detail.js" /* webpackChunkName: "component---src-pages-blog-detail-js" */),
  "component---src-pages-blog-list-js": () => import("./../../../src/pages/blog-list.js" /* webpackChunkName: "component---src-pages-blog-list-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kipo-js": () => import("./../../../src/pages/kipo.js" /* webpackChunkName: "component---src-pages-kipo-js" */),
  "component---src-pages-monibyte-app-js": () => import("./../../../src/pages/monibyte-app.js" /* webpackChunkName: "component---src-pages-monibyte-app-js" */),
  "component---src-pages-monibyte-saas-js": () => import("./../../../src/pages/monibyte-saas.js" /* webpackChunkName: "component---src-pages-monibyte-saas-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

